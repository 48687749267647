import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './services/auth.service';
import { IonRouterOutlet, MenuController } from '@ionic/angular';
import { UpdateService } from './services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  constructor(private authService: AuthService, private menuController: MenuController, private sw: UpdateService) {}
  ngOnInit() {
    this.sw.checkForUpdates();

    this.menuController.swipeGesture(false);

    this.routerOutlet.swipeGesture = false;
  }

  public appPages = [
    { title: 'Mine kørsler', url: '/main/tasks', icon: 'list' },
    { title: 'Min Profil', url: '/main/profile', icon: 'person' },
  ];

  logout() {
    this.authService.logout();
  }
}
