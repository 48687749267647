import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

interface AuthResponseData {
  token: string;
}
const TOKEN_KEY = 'AUTH_TOKEN';
const USER_KEY = 'CURRENT_USER';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _token = new BehaviorSubject<String>(null);
  @Output() logoutEvent: EventEmitter<any> = new EventEmitter();

  constructor(private storageService: StorageService, private router: Router, private http: HttpClient) {}

  get userIsAuthenticated() {
    return this._token.asObservable().pipe(
      map((token) => {
        return token ? true : false;
      })
    );
  }

  get token() {
    return this._token.value;
  }

  checkHasStoredLogin() {
    return from(
      this.storageService.retrieve<string>(TOKEN_KEY).then((token) => {
        if (!token) {
          return null;
        }

        //token found
        this._token.next(token);

        return !!token;
      })
    );
  }

  login(email: string, password: string, device_name: string) {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('ngsw-bypass', 'true');

    return this.http
      .post<AuthResponseData>(environment.webservice.baseurl + 'token', { email, password, device_name }, { headers })
      .pipe(tap(this.setAuthentication.bind(this)));
  }

  async setAuthentication(authData: AuthResponseData) {
    this._token.next(authData.token);

    await this.storageService.store<string>(TOKEN_KEY, authData.token);
  }

  async logout() {
    this._token.next(null);
    await this.storageService.clear();
    this.logoutEvent.emit(true);

    this.router.navigateByUrl('/auth', { replaceUrl: true });
  }
}
