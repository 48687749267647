import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public async retrieve<T>(key: string) {
    const storageResult = await Storage.get({ key });
    return JSON.parse(storageResult.value) as T;
  }

  public store<T>(key: string, data: T) {
    return Storage.set({
      key,
      value: JSON.stringify(data),
    });
  }

  public remove<T>(key: string) {
    return Storage.remove({ key });
  }

  public clear<T>() {
    return Storage.clear();
  }
}
